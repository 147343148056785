export const API_URL = "https://clepbcba-api.onrender.com"

/*
  dev:  http://localhost:3001
  prod: https://clepbcba-api.onrender.com
*/

export const AUTHNET_SCRIPT = "https://js.authorize.net/v1/Accept.js"
export const AUTHNET_LOGINID = "783XsgT7dfBr"
export const AUTHNET_PUBKEY =
  "9tLa9S39J4dJc5kzG6rLMv4WRkJM43qvdMwaS4Gq8wmMXAA6Dqw8XE5UC9GF32fY"
/* Sandbox - qnm
    https://jstest.authorize.net/v1/Accept.js
    34fhtk8Be8C7
    44S79RZmJ3jf7teah5u8wp95WCcRHX2rJ3S6a48Hx3t6w6jarbaRQFnGEaLVcLd6

  Live - pbcba
    https://js.authorize.net/v1/Accept.js
    783XsgT7dfBr
    9tLa9S39J4dJc5kzG6rLMv4WRkJM43qvdMwaS4Gq8wmMXAA6Dqw8XE5UC9GF32fY
*/

export function isFieldError(array, val) {
  return array.includes(val)
}

export function createMarkup(html) {
  return { __html: html }
}

const isBrowser = () => typeof window !== "undefined"

export const getValFromLS = (key, useSessionStorage = false) => {
  if (useSessionStorage) {
    return isBrowser() && window.sessionStorage.getItem(key)
      ? window.sessionStorage.getItem(key)
      : undefined
  } else {
    return isBrowser() && window.localStorage.getItem(key)
      ? window.localStorage.getItem(key)
      : undefined
  }
}

export const setValToLS = (key, val, useSessionStorage = false) => {
  if (isBrowser() && val) {
    useSessionStorage
      ? window.sessionStorage.setItem(key, val)
      : window.localStorage.setItem(key, val)
  } else {
    useSessionStorage
      ? window.sessionStorage.removeItem(key)
      : window.localStorage.removeItem(key)
  }
  return true
}

export function fmtCurrency(int) {
  const amt = int
  return `${amt.toLocaleString("US", {
    style: "currency",
    currency: "USD",
  })}`
}

export function getVal(array, value, key) {
  let val = ""
  array.forEach(item => {
    if (item.value === value) {
      val = item[key]
    }
  })
  return val
}
